<template>
  <div class="flex flex-col h-screen w-full items-center justify-center bg-white">
    <Maintenance v-if="!isEnabledForgotPassword" />
    <section
      v-else
      class="flex flex-col items-center justify-center gap-4"
      data-cy="token-expired__container"
    >
      <div class="w-[225px] h-[225px]">
        <img
          src="@/assets/images/expired-token.svg"
          alt="Ilustrasi Tautan Kedaluwarsa"
          width="225"
          height="225"
        >
      </div>
      <h1
        class="text-green-700 text-[21px] font-bold font-lora text-center"
        data-cy="token-expired__title"
      >
        Tautan Atur Ulang Kata Sandi Telah Kedaluwarsa !
      </h1>
      <p
        class="text-blue-gray-800 text-center font-lato text-sm"
        data-cy="token-expired__message"
      >
        Maaf, tautan reset kata sandi yang Anda gunakan sudah tidak berlaku. Demi keamanan, tautan ini hanya berlaku selama 1 jam sejak dikirimkan.
      </p>
      <BaseButton
        type="button"
        class="bg-green-700 hover:bg-green-600 font-lato text-sm text-white"
        data-cy="token-expired__back-button"
        @click="onClickBack()"
      >
        <template #icon-left>
          <JdsIcon
            name="arrow-left"
            size="16px"
            fill="#fff"
            class="h-4 w-4"
          />
        </template>
        <p>
          Kembali
        </p>
      </BaseButton>
    </section>
  </div>
</template>

<script>
import BaseButton from '@/common/components/BaseButton';

export default {
  name: 'TokenExpired',
  components: {
    BaseButton,
  },
  computed: {
    isEnabledForgotPassword() {
      return this.$unleash.isEnabled('CMS-FORGOT-PASSWORD');
    },
  },
  methods: {
    onClickBack() {
      this.$router.push('/login');
    },
  },
};
</script>
